<template>
  <div class="text" style="width: 100%;height: 100%;margin-top: 60%;text-align: center;font-size: 15px">
    如支付已完成，请关闭此页面，否则请重新发起支付
  </div>
</template>
<script>
export default {
  created() {
    if (this.$route.query.key) {
      console.log('this.$route.query.key：' + this.$route.query.key)
      window.location.href = this.$route.query.key;
    }
  }
}
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0px;
}

.text {
  width: 100%;
  height: 200px;
  text-align: center;
  /* line-height: 200px; */
  margin-top: 50px;
  font-size: 0.16rem;
}

</style>
